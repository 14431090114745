.App {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-family: 'Noto Sans', sans-serif;
}

.App .row.header {
  flex: 0 1 auto;
  background-color: #ffffff;
  font-size: calc(10px + 2vmin);
  color: #31296C;
}

.App .row.footer {
  font-size: x-small;
  flex: 0 1 50px;
  position: fixed;
  width: 100vw;
  bottom: 0;
  text-align: center;
}