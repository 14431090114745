.article-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.article {
  width: 60%;
}

.article a {
  font-weight: bold;
  text-decoration-line: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
tr:hover {
  background-color: #ddd;
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #31296C;
  color: white;
}
